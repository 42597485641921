import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ReactComponent as RightArrow } from "../../static/tildi-right-arrow-icon.svg";
import { ReactComponent as DeleteIcon } from "../../static/tildi-cross-icon.svg";
import { useEffect, useLayoutEffect, useRef, useState } from 'react';



const OperationButtonsWrapper = styled.div`
  // max-width: 100px;
  z-index: 99;
  opacity: 0.7;
  position: fixed;
  bottom: 10px;
  // top: ${props => props.$top}
  overflow-y: hidden;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  justify-content: space-between;
  .left-buttons, .right-buttons{
    display: flex;
    flex-direction: column;
  }
  .operation-button-wrapper{
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-end;
    .operation-text{
      transition: all 0.5s ease-out;
      background-color: #3e5d4354;
      padding: 3px;
      margin-right: 5px;
      color: white;
      opacity: ${props => props.$showbuttontext === 'true' ? "1" : "0"};
    }
    .operation-button{
      z-index:999;
      color: white;
      height: 40px;
      width: 40px;
      border-radius: 40px;
      border: 1px solid black;
      display: flex;
      align-items: center;
      justify-content: center;
      .button-text{
        padding: 10px 14px;
        font-weight: bold;
      }
      path{
        fill: white;
      }
    }
    .text-transition{
      opacity:0;
    }
  }
`

const CompleteButtonWrapper = styled.div`
  .operation-button{
    background-color: #00a400;
  }
`

const AddItemButtonWrapper = styled.div`
  .operation-button{
    background-color: blue;
  }
`

const NextButtonWrapper = styled.div`
  .operation-button{
    background-color: #135f1b;
  }
`
const CancelButtonWrapper = styled.div`
  .operation-button{
    background-color: #b70000;
    svg{
      height: 15px;
      width: 15px;
    }
  }
`

const CustomButtonWrapper = styled.div`
  &.custom-button-wrapper{
    .operation-button{
      height: 32px;
      width: 32px;
      background-color: #057aa6a8;
    }
  }
`

const RightButtonWrapper = styled(OperationButtonsWrapper)`
  right: 5px;
  .complete-button-wrapper{
    display: ${props => props.$showcompletebutton === 'true' ? "flex" : "none"};
  }
  .add-button-wrapper{
    display: ${props => props.$showaddbutton === 'true' ? "flex" : "none"};
  }
  .next-button-wrapper{
    display: ${props => props.$shownextbutton === 'true' ? "flex" : "none"};
  }
`
const LeftButtonWrapper = styled(OperationButtonsWrapper)`
  left: 5px;
  .cancel-button-wrapper{
    display: ${props => props.$showCancelButton === 'true' ? "flex" : "none"};
  }
`

const BUTTON_OFFSET = 35;

function BottomRightOperationTools(props) {
  // const [size, setSize] = useState([0, 0]);
  // const [top, setTop] = useState([0,0]);
  // const rightButtonWrapperRef = useRef(null);
  // const leftButtonWrapperRef = useRef(null);
  // useEffect(()=>{
  //   // console.log(rightButtonWrapperRef.current.clientHeight);
  //   var rightButtonHeight = rightButtonWrapperRef.current.clientHeight;
  //   var leftButtonHeight = leftButtonWrapperRef.current.clientHeight;
  //   setTop([`${size[1] - (BUTTON_OFFSET + leftButtonHeight)}px;`, `${size[1] - (BUTTON_OFFSET + rightButtonHeight)}px;`]);
  // },[size])
  // useLayoutEffect(() => {
  //   function updateSize() {
  //     setSize([window.innerWidth, window.innerHeight]);
  //   }
  //   window.addEventListener('resize', updateSize);
  //   updateSize();
  //   return () => window.removeEventListener('resize', updateSize);
  // }, []);
  return (
    <>
      <LeftButtonWrapper
        $showbuttontext={props.showButtonText ? props.showButtonText.toString() : 'false'}
        $showCancelButton={props.showCancelButton ? props.showCancelButton.toString() : 'false'}
        // $top={top[0]}
        // ref={leftButtonWrapperRef}
      >
        <CancelButtonWrapper className="operation-button-wrapper cancel-button-wrapper">
          <div
            className="cancel-operation-button operation-button"
            onClick={props.onClickCancel}
          >
            <DeleteIcon />
          </div>
          <div className="operation-text">
            {props.cancelButtonText ? props.cancelButtonText : "Cancel"}
          </div>
        </CancelButtonWrapper>
      </LeftButtonWrapper>
      <RightButtonWrapper
        $showbuttontext={props.showButtonText ? props.showButtonText.toString() : 'false'}
        $showcompletebutton={props.showCompleteButton ? props.showCompleteButton.toString() : 'false'}
        $showaddbutton={props.showAddButton ? props.showAddButton.toString() : 'false'}
        $shownextbutton={props.showNextButton ? props.showNextButton.toString() : 'false'}
        // $top={top[1]}
        // ref={rightButtonWrapperRef}
      >
        {
          props.additionalRightButtons && props.additionalRightButtons.map((additionalButton, idx)=>{
            return (
              <CustomButtonWrapper 
                key={`custom-button-operator-${idx}`}
                className='operation-button-wrapper custom-button-wrapper'
              >
                <div className='operation-text'>{additionalButton.text}</div>
                <div 
                  className='operation-button'
                  onClick={(e) => {
                    e.stopPropagation();
                    additionalButton.onClick();
                  }}
                >
                  {additionalButton.icon}
                </div>
              </CustomButtonWrapper>
            );
          })
        }
        <CompleteButtonWrapper className="operation-button-wrapper complete-button-wrapper">
          <div className="operation-text">
            {props.completeButtonText ? props.completeButtonText : "Complete"}
          </div>
          <div
            className="next-operation-button operation-button"
            onClick={props.onClickComplete}
          >
            <div className="button-text">
              ✓
            </div>
          </div>
        </CompleteButtonWrapper>
        <AddItemButtonWrapper className="operation-button-wrapper add-button-wrapper">
          <div className="operation-text">
            {props.addButtonText ? props.addButtonText : "Add"}
          </div>
          <div
            className="add-operation-button operation-button"
            onClick={props.onClickAdd}>
            <div className="button-text">
              +
            </div>
          </div>
        </AddItemButtonWrapper>
        <NextButtonWrapper className="operation-button-wrapper next-button-wrapper">
          <div className="operation-text">
            {props.nextButtonText ? props.nextButtonText : "Next"}
          </div>
          <div
            className="next-operation-button operation-button"
            onClick={props.onClickNext}>
            <RightArrow />
          </div>
        </NextButtonWrapper>
      </RightButtonWrapper>
    </>
  );
}

BottomRightOperationTools.propTypes = {
  showCompleteButton: PropTypes.bool,
  completeButtonText: PropTypes.string,
  onClickComplete: PropTypes.func,
  showAddButton: PropTypes.bool,
  addButtonText: PropTypes.string,
  onClickAdd: PropTypes.func,
  showNextButton: PropTypes.bool,
  nextButtonText: PropTypes.string,
  onClickNext: PropTypes.func,
  showButtonText: PropTypes.bool,
  showCancelButton: PropTypes.bool,
  cancelButtonText: PropTypes.string,
  onClickCancel: PropTypes.func,
}

export default BottomRightOperationTools;