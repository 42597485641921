
import styled from 'styled-components';
import BottomRightOperationTools from "../../../common/components/BottomRightOperationTools";
import { ReactComponent as BackArrow} from "../../../static/tildi-double-left-arrow-icon.svg";
import { useState } from "react";
import { ReactComponent as DeleteIcon} from "../../../static/tildi-cross-icon.svg";
import RaasItemBasicDetailScreen from './RaasItemBasicDetailScreen';

const RaasOrderItemBasicListingScreenWrapper = styled.div`
  margin: 20px 15px;
  text-align: center;
  // min-height: 910vh;
  position: relative;
  padding-bottom: 100px;
  .home-screen-title{
      // text-align: center;
      font-weight: bold;
      font-size: 20px;
  }
  .product-list-screen-detail{
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .status_text{
      display: flex;
      width: 80px;
      height: 15px;
      text-align: center;
      padding: 4px;
      color: white;
      margin-left: 20px;
      align-items: center;
      justify-content: center;
      font-size: 13px;

      &.status_100{
        background-color: #C5C5C5;;
      }
      &.status_200{
        background-color: #04bb2d;
      }
      &.status_400{
        background-color: #b70000;
      }
      &.status_300{
        background-color: #e1c534;
      }
    }
  }
  .home-screen-filters-container{
    min-height: 50px;
    font-size: 14px;
    color: grey;
    text-align: initial;
    margin-top: 15px;
    margin-bottom: 5px;
    padding: 10px 13px;
    background: #fff1fc;
    position: relative;
    border: 1px solid black;
    .filter-send-button{
      height: 25px;
      padding: 0 5px;
      background-color: grey;
      font-size: 15px;
      min-width: 0px;
      position: absolute;
      top: 6px;
      right: 10px;
    }
    .filter-reset-button{
      position: absolute;
      bottom: 6px;
      right: 10px;
      text-decoration: underline;
      font-size: 16px;
    }

    .home-scrren-filters-wrapper{
      margin-top: 5px;
      .filter-field-wrapper{
        display: flex;
        flex-direction: row;
        align-items: center;
        .filter-input{
          height: 20px;
          font-size: 15px;
          // margin: 0 10px;
          padding: 0 2px;
          margin-top: 5px;
        }
      }
    }
  }
  .scanner-dialog{
    position: absolute;
    top: 20%;
    height: 50vh;
    width: 80vw;
    background-color: white;
    #scanner-section{
      margin: 40px 0;
      width: 100%;
      video{
        width: 80%;
      }
    }
  }
`

const Table = styled.div`
    font-size: 13px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
`

const TableRow = styled.div`
  position: relative;
  padding: 5px 10px 5px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid black;
  margin-bottom: 10px;
  background-color: #EDF9ED;
  .item-delete-icon{
    position: absolute;
    background-color: #cb0505;
    height: 20px;
    width: 20px;
    border-radius: 20px;
    border: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -7px;
    right: -8px;
    svg{
      height: 10px;
      width: 10px;
      path{
        fill: white;
        stroke: white;
      }
    }
  }
`

const TableCellDataWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`

const TableCellData = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px;
  text-align: left;
  min-width: 80px;

  .table-cell-data-title{
    color: #A8B1A9;
    font-size: 10px;
  }
  .table-cell-data{
    font-size: 13px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`

const BackButton = styled.div`
  display: flex;
  font-size: 12px;
  align-items: center;
  position: absolute;
  top: 0;
  padding: 3px 10px 3px 5px;
  background: #405c43;
  color: white;
  border-radius: 15px;
  font-weight: bold;
  .back-icon{
    height: 18px;
  }
`

const FIELDS_TO_SHOW = [
  {
    "id":"product_id",
    "label":"Produkt Id",
    "type":"text",
    "isMandatory": true,
    "showScanner": true,
  },
  {
    "id":"condition",
    "label":"Zustand",
    "type":"drop_down",
    "isMultiselect": false,
    "isMandatory": true
  },
  {
    "id":"segment",
    "label":"Segment",
    "type":"drop_down",
    "isMultiselect": true,
    "isMandatory": true
  },
  {
    "id":"quantity",
    "label":"Quantity",
    "type":"integer",
    "isMandatory": true
  },
  {
    "id":"comments",
    "label":"Comments",
    "type":"text_area",
    "isMandatory": false
  },
]

const LIST_SCREEN_KEY = "LIST_SCREEN";
const CREATE_SCREEN_KEY = "CREATE_SCREEN";
const UPDATE_SCREEN_KEY = "UPDATE_SCREEN";

const getTableRowsForDataAvailableType = (orderItems, conditionsList, segmentList, isEditable, fItemOnDelete, fOnClickItem) => {

  var tableRows = orderItems.map((orderItem) => {
    const data = [];
    const conditionObject = conditionsList.find((condition) => {return condition.id == orderItem.condition})
    const segmentObject = segmentList.find((segment) => {return segment.id == orderItem.segment})
    data.push(
      <TableCellData key={`table-cell-sku-${orderItem.id}`}>
        <div key={`cell-data-title-sku_${orderItem.id}`} className="table-cell-data-title">SKU</div>
        <div key={`cell-data-sku_${orderItem.id}`} className="table-cell-data cell-data-sku">
          #{orderItem.sku}
        </div>
      </TableCellData>
    )
    data.push(
      <TableCellData key={`table-cell-product-id-${orderItem.id}`}>
        <div key={`cell-data-title-productid_${orderItem.id}`} className="table-cell-data-title">Product Id</div>
        <div key={`cell-data-productid_${orderItem.id}`} className="table-cell-data cell-data-productid">
          {orderItem['product_id']}
        </div>
      </TableCellData>
    )
    data.push(
      <TableCellData key={`table-cell-segment-${orderItem.id}`}>
        <div key={`cell-data-title-segment_${orderItem.id}`} className="table-cell-data-title">Segment</div>
        <div key={`cell-data-segment_${orderItem.id}`} className="table-cell-data cell-data-segment">
          {/* {new Date(order['created']).toDateString() } */}
          {segmentObject ? segmentObject.label : ''}
        </div>
      </TableCellData>
    )
    data.push(
      <TableCellData key={`table-cell-condition-${orderItem.id}`}>
        <div key={`cell-data-title-condition_${orderItem.id}`} className="table-cell-data-title">Condition</div>
        <div key={`cell-data-condition_${orderItem.id}`} className="table-cell-data cell-data-condition">
          {conditionObject ? conditionObject.label : ''}
        </div>
      </TableCellData>
    )
    data.push(
      <TableCellData key={`table-cell-quantity-${orderItem.id}`}>
        <div key={`cell-data-title-quantity_${orderItem.id}`} className="table-cell-data-title">Quantity</div>
        <div key={`cell-data-quantity_${orderItem.id}`} className="table-cell-data cell-quantity">
          {orderItem['quantity']}
        </div>
      </TableCellData>
    )
    return (
      <TableRow key={orderItem.id} className="table-row" onClick={() => {fOnClickItem(orderItem)}}>
        <TableCellDataWrapper key={`${orderItem.id}-table-cell-wrapper`} className="table-cell-data-wrapper">{data}</TableCellDataWrapper>
        {isEditable && <div 
          className="item-delete-icon" 
          onClick={(e)=>{
            e.stopPropagation(); 
            var isConfirm = window.confirm("Are you sure you want to delete the product?");
            if (isConfirm){
              fItemOnDelete(orderItem.id);
            } 
          }}
        >
          <DeleteIcon/>
        </div>}
      </TableRow>
    );
  });
  return tableRows;
}


const RaasOrderItemBasicListingScreen = (props) => {
  const [screenMode, setScreenMode] = useState(LIST_SCREEN_KEY);
    const [showCreateNew, setShowCreateNew] = useState(false);
    const [productEdit, setProductEdit] = useState(false);
    const [createFields, setCreateFields] = useState({});
    const [updateFields, setUpdateFields] = useState({});
    const orderItems = props.orderItems;
    const conditionsList = props.conditionsList;
    const segmentList = props.segmentList;
    const orderStatusConstants = props.orderStatusConstants;
    const dropDownValues = {
      condition: conditionsList,
      segment: segmentList
    }
    const order = props.order;
    var isEditable = order.status != 205;
    const orderId = order ? order.id : "";
    var tableRows = [];
    if(orderItems){
        tableRows = getTableRowsForDataAvailableType(orderItems, conditionsList, segmentList, isEditable, props.onDelete, 
          (oOrderItem)=>{
            setUpdateFields(oOrderItem);
            setProductEdit(oOrderItem);
            setScreenMode(UPDATE_SCREEN_KEY)
          }
        );
    }
    var screenToShow = LIST_SCREEN_KEY;
    switch(screenMode){
      case LIST_SCREEN_KEY:
        screenToShow = (<Table className="table">{tableRows}</Table>);
        break;
      case CREATE_SCREEN_KEY:
        screenToShow = (<RaasItemBasicDetailScreen
          title="Create"
          propertiesToShow={FIELDS_TO_SHOW}
          fieldValues={createFields}
          dropDownValues={dropDownValues}
          onClose={()=>{
            setCreateFields({});
            setShowCreateNew(false);
            setScreenMode(LIST_SCREEN_KEY);
          }}
          onChangeValue={(propertyId, value) => {
            var newCreateFields = {...createFields};
            newCreateFields[propertyId] = value;
            setCreateFields(newCreateFields);
          }}
          onComplete={() => {
            var item = {...createFields};
            item['order'] = orderId;
            props.createNew(item, ()=>{
              setCreateFields({});
              setShowCreateNew(false)
              setScreenMode(LIST_SCREEN_KEY);
            })
          }}
        />);
        break;
      case UPDATE_SCREEN_KEY:
        screenToShow = (<RaasItemBasicDetailScreen
          title="Update"
          propertiesToShow={FIELDS_TO_SHOW}
          fieldValues={updateFields}
          dropDownValues={dropDownValues}
          onClose={()=>{
            setUpdateFields({});
            setProductEdit(false);
            setScreenMode(LIST_SCREEN_KEY)
          }}
          onChangeValue={(propertyId, value) => {
            var newUpdateFields = {...updateFields};
            newUpdateFields[propertyId] = value;
            setUpdateFields(newUpdateFields);
          }}
          onComplete={() => {
            var product = productEdit;
            if (product !== updateFields){
              for (var i = 0 ; i < FIELDS_TO_SHOW.length ; i++){
                var property = FIELDS_TO_SHOW[i];
                product[property.id] = updateFields[property.id];
              }
              props.handleUpdateItem(product, ()=>{
                setUpdateFields({});
                setProductEdit(false);
                setScreenMode(LIST_SCREEN_KEY);
              })
            }
          }}
        />);
        break;
      default:
        screenToShow = LIST_SCREEN_KEY;
    }
    return (
        <RaasOrderItemBasicListingScreenWrapper>
            <BackButton
                onClick={screenMode == LIST_SCREEN_KEY ? 
                  props.onClickBack : 
                  ()=>{
                    setUpdateFields({});
                    setProductEdit(false);
                    setCreateFields({});
                    setShowCreateNew(false);
                    setScreenMode(LIST_SCREEN_KEY);
                  }
                }
            >
              <BackArrow className="back-icon"/>
                {screenMode == LIST_SCREEN_KEY ? 'Orders' : 'Products'}
            </BackButton>
            <div className="home-screen-title">RaaS Order Items</div>
            <div className="product-list-screen-detail">
                <div className="product-list-screen-text"><b>Raas:</b> {order['transaction_number']}</div>
                  <div className={`status_text ${order ? "status_" + order.status : ""}`}>{order && orderStatusConstants ? orderStatusConstants[order.status] : ""}</div>
              </div>
            {screenToShow}
            {
              productEdit || showCreateNew ?
              ""
              :
              <BottomRightOperationTools 
                showCompleteButton={isEditable}
                showAddButton={isEditable}
                showButtonText={true}
                onClickAdd={() => {setShowCreateNew(true);setScreenMode(CREATE_SCREEN_KEY)}}
                onClickComplete={()=>{props.handleOrderComplete(200)}}
              />
            }
        </RaasOrderItemBasicListingScreenWrapper>
    )
}

export default RaasOrderItemBasicListingScreen;