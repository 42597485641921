import { useEffect, useState, createRef } from 'react';
import styled from 'styled-components';
// import properties from '../../../mock/property_fields.json'
import propertyMapping from '../../../mock/request_property_order_item_property_mapping.json'
import CommonInputField from '../../../common/components/CommonTextField';
import CommonDropDownField from '../../../common/components/CommonDropDownField';
import {getRequest, postRequest} from "../../../common/utils/request";
import { CREATE_WAREHOUSE_TRACKING, WAREHOUSE_ATTRIBUTES_FETCH } from '../../../common/utils/urlPath';
import AuthService from '../../../common/components/services/AuthService';
import { ATTRIBUTE_TYPE_CURRENCY, ATTRIBUTE_TYPE_DROP_DOWN, ATTRIBUTE_TYPE_FLOAT, ATTRIBUTE_TYPE_INTEGER, ATTRIBUTE_TYPE_MEASUREMENT, ATTRIBUTE_TYPE_TEXT, ATTRIBUTE_TYPE_TEXT_AREA } from '../../../common/utils/commonConstants';
import BottomRightOperationTools from '../../../common/components/BottomRightOperationTools';
import {ReactComponent as CheckIcon} from '../../../static/tildi-check-icon.svg';
import {ReactComponent as CrossIcon} from '../../../static/tildi-cross-icon.svg';
import {ReactComponent as PlusIcon} from '../../../static/tildi-plus-icon.svg';
import CommonTextArea from '../../../common/components/CommonTextArea';
import { ReactComponent as UpArrow } from "../../../static/tildi-up-arrow-icon.svg";
import { ReactComponent as DownArrow } from "../../../static/tildi-down-arrow-icon.svg";
import BarcodeScanner from '../../../common/components/BarcodeScanner';
import { toast } from "react-toastify";


const WizardProductAttributesWrapper = styled.div`
  margin: 0 0 10px 0;
  width: 100%;
  div.product-attributes-section{
    display:flex;
    width: 100%;
    margin: 20px 0;
    flex-direction: column;
    div.product-attibutes-filler-wrapper{
      margin: 20px 0;
      width: 100%;
    }
    div.product-attributes-images-wrapper{
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .product-image{
        background-color: #efefef;
        padding: 8px;
        border: 0.5px solid #d9d9d9;
        // max-width: 100%;
        height: 90px;
      }
    }
  }
`

const FieldWrapper = styled.div`
  position: relative;
  margin-top: 20px;
  .status-icon{
    position: absolute;
    // width: 14px;
    z-index: 9;
    top: -6px;
    right: -6px;
  }
`

const ValueNotificationButton = styled.div`
  position: absolute;
  right: 6px;
  top: 11px;
  color: rgba(0, 0, 0, 0.5);
  z-index: 90;
  border: 1px solid #e65eff;
  border-radius: 20px;
  padding: 5px;
  height: 15px;
  width: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg{
    // width: 25px;
    transition: transform 0.2s linear;
    &.show-suggestion{

    }
    &.close-suggestion{
      transform: rotate(45deg);
    }
    path{
      fill: #e65eff;
    }
  }
`

const ModalDialogBody = styled.div`
  position: relative;
  height: 100%;
  top: -12px;
  border: 1px solid #b6cbb8;
  padding: 0;
  margin: 0;
  font-size: 12px;
`

const ValueSuggestionWrapper = styled.div`
  .value-suggestion-attribute-title{
    font-size: 10px;
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 1px; 
  }
  .value-suggestion-attribute-value{
    margin-bottom: 10px;

    &.value-acceptance-body{
      background-color: #f2f7f3;
      padding: 5px;
      margin: 0;
      font-size: 18px;
    
      .text-value-suggestion-section{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .text-value-suggestion{
          width: 250px;
        }
      }

      .drop-down-value-suggestion-section{
        .drop-down-option-suggestion-match{
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: space-between;
          text-align: left;
          // font-size: 14px;
          align-items: center;
          .drop-down-orginal-value, .drop-down-match-value{
            width: 110px;
            background-color: white;
            padding: 4px;
            border-radius: 5px;
            margin: 2px;
          }
        }
      }
      
      .value-accept-button{
        width: 13px;
        padding: 0 6px;
        border-radius: 15px;
        border: 0.5px solid #95af98;
        background-color: #405c43;
        path{
          fill: white;
        }
      }
      .value-done-button{
        width: 20px;
        path{
          fill: #00c300;
        }
      }
    }
  }
`

const IconElement = styled.div`
  &.complete-icon{
    background-color: green;
  }
  &.incomplete-icon{
    background-color: red;
  }
  height: 10px;
  width: 10px;
  padding: 2px;
  display: flex;
  /* border-radius: 10px; */
  align-items: center;
  justify-content: center;
  svg{
    height: 7px;
    width: 7px;
    path{
      fill: white;
    }
  }
`;

const ShowBarcodeScannerButton = styled.div`
  position: absolute;
  top: 15px;
  right: 10px;
  input[type="file"]{
    display: none;
  }
`

const CompleteIcon = (props) => {
  return (<IconElement className={`${props.className} complete-icon`}><CheckIcon/></IconElement>);
}

const IncompleteIcon = (props) => {
  return (<IconElement className={`${props.className} incomplete-icon`}><CrossIcon/></IconElement>);
}

const SELLER_PROPERTY_ID = "seller";

const WizardProductAttributesStep = (props) => {
  const [stateProperties, setStateProperties] = useState([]);
  const [modalRenderData, setModalRenderData] = useState({});
  const [startTime, setStartTime] = useState(Date.now());
  const [currentFocusIndex, setCurrentFocusIndex] = useState(0);
  const [propertyRefs, setPropertyRefs] = useState([]);
  const [defaultWarehouse, setDefaultWarehouse] = useState('');
  const currentProduct = props.currentProduct;
  const commonData = currentProduct.order.common_data;
  useEffect(() => {
    getRequest(`${WAREHOUSE_ATTRIBUTES_FETCH}?category=${encodeURIComponent(props.currentProduct.category)}`, (response) => {
      var userInfo = AuthService.getUserInfo();
      var defaultWarehouseKey = userInfo && userInfo.warehouse ? userInfo.warehouse['associated_key'] : undefined;
      setPropertyRefs(Array.from({ length: response.length }, () => createRef()));
      var propertiesClone = [...response];
      propertiesClone.forEach((property)=> {
        var isMultiSelect = property.isMultiselect;
        var hasProductProperty = (!isMultiSelect && props.currentProduct[property.id]) || (isMultiSelect && props.currentProduct[property.id] && props.currentProduct[property.id].length > 0);
        var hasCommonData = (!isMultiSelect && commonData && commonData[property.id]) || (isMultiSelect && commonData && commonData[property.id] && commonData[property.id].length > 0);
        if(hasProductProperty){
          property.value = props.currentProduct[property.id];
        } else if(hasCommonData){
          property.value = commonData[property.id];
        } else if(property.default){
          if(isMultiSelect){
            property.value = property.default.split(',');
          } else {
            property.value = property.default;
          }
        } else if(property.id === SELLER_PROPERTY_ID && defaultWarehouseKey){
          property.value = defaultWarehouseKey;
        }
      });
      setStateProperties(propertiesClone);
    })
  }, [props.currentProduct]);

  useEffect(() => {
    propertyRefs[currentFocusIndex]?.current?.focus();
  }, [propertyRefs, currentFocusIndex]);

  const onChangeValue = (field, value) => {
    var newStateProperties = [...stateProperties]
    var propertyToUpdate = newStateProperties.find((stateProperty)=>{return stateProperty.id === field})
    propertyToUpdate.value = value;
    setStateProperties(newStateProperties)
  }
  
  const getModalDialogContent = () => {
    if(modalRenderData.property){
      const modalProperty = modalRenderData.property;
      const propertyToSuggest = stateProperties.find((stateProperty)=>{return stateProperty.id === modalProperty.id});
      const requestItemArticleProperty = propertyMapping[modalProperty.id];
      const originalValue = currentProduct.request_item ? currentProduct.request_item.article[requestItemArticleProperty] : undefined;
      const currentValue = propertyToSuggest.value;
      var renderContent = "";
      switch(propertyToSuggest.type){
        case ATTRIBUTE_TYPE_TEXT:
          var isValueSame = originalValue === currentValue
          renderContent = (
            <div className="text-value-suggestion-section">
              <div className="text-value-suggestion">{originalValue}</div>
              {
                isValueSame ? 
                // <img src={doneButton} className="value-done-button"/> 
                <CheckIcon className="value-done-button"/>
                : 
                <PlusIcon
                  className="value-accept-button" 
                  onClick={() => {
                      onChangeValue(propertyToSuggest.id, originalValue);
                      setModalRenderData({});
                    }
                  }
                />
              }
            </div>
          )
          break;
        
        case ATTRIBUTE_TYPE_DROP_DOWN:
          var possibleOptions = originalValue.split(",");
          var options = props.dropDownValues[propertyToSuggest.id];
          var optionMatches = possibleOptions.map((possibleOptionItem, index) => {
            var match = options.find((option) => {
              return option.label.trim().toLowerCase() === possibleOptionItem.trim().toLowerCase() || 
                option.id.trim().toLowerCase() === possibleOptionItem.trim().toLowerCase();
            });
            var isMatchAdded = false;
            if(match){
              if(propertyToSuggest.isMultiselect && propertyToSuggest.value){
                if(propertyToSuggest.value.includes(match.id)){
                  isMatchAdded = true;
                }
              } else {
                isMatchAdded = propertyToSuggest.value === match.id;
              }
            }
            return (
              <div key={`drop-down-suggestions-${match != null ? match.id.replace(' ','_') : index}`} className="drop-down-option-suggestion-match">
                <div className="drop-down-orginal-value">{possibleOptionItem}</div>
                <div className="drop-down-match-value">{match ? match.label : ""}</div>
                <div className="option-selection-button-wrapper">
                  {
                    match ? 
                    ( isMatchAdded ? 
                      (<CheckIcon className="value-done-button"/>)
                      :
                      (
                        <PlusIcon
                          className="value-accept-button" 
                          onClick={() => {
                            if(propertyToSuggest.isMultiselect){
                              var newValues = propertyToSuggest.value ? [...propertyToSuggest.value] : [];
                              newValues.push(match.id);
                              onChangeValue(propertyToSuggest.id, newValues)
                            } else {
                              onChangeValue(propertyToSuggest.id, match.id)
                              setModalRenderData({})
                            }
                          }}
                        />
                      )
                    ) 
                    : 
                    ""}
                </div>
              </div>
            );
          });
          renderContent = (
            <div className="drop-down-value-suggestion-section">
              {optionMatches}
            </div>
          )
          break;
        
        default:
          renderContent = (<div></div>);
      }
      return (<ValueSuggestionWrapper>
        <div className="value-suggestion-attribute-value value-acceptance-body">{renderContent}</div>
      </ValueSuggestionWrapper>)
    }
  }

  const focusOnNextField = (propertyRef) => {
    var index = undefined;
    if(propertyRef){
      index = propertyRefs.indexOf(propertyRef);
    } else {
      index = currentFocusIndex;
    }
    if (index === propertyRefs.length - 1) {
      document.activeElement.blur();
    } else if (index > propertyRefs.length - 1) {
      index = -1;
    }
    setCurrentFocusIndex(index + 1);
  }

  const focusOnPreviousField = (propertyRef) => {
    var index = undefined;
    if(propertyRef){
      index = propertyRefs.indexOf(propertyRef);
    } else {
      index = currentFocusIndex;
    }
    if(index === 0){
      document.activeElement.blur();
    } else if(index === -1){
      index = propertyRefs.length - 1
    }
    setCurrentFocusIndex(index - 1);
  }
  
  const getPropertyDisplayField = (property, isPropertyFilled, propertyRef) => {
    const isAutoFocus = propertyRef === propertyRefs[currentFocusIndex];
    const propertyStatusIcon = property.isMandatory ? 
      (!isPropertyFilled ? <IncompleteIcon className="incomplete-icon status-icon"/> : <CompleteIcon className="complete-icon status-icon"/> ) : 
      (isPropertyFilled ? <CompleteIcon className="complete-icon status-icon"/> : "");
    const requestItemArticleProperty = propertyMapping[property.id];
    const originalValue = currentProduct.request_item && currentProduct.request_item.article ? currentProduct.request_item.article[requestItemArticleProperty] : "";
    const showPropertySuggestion = modalRenderData.property && modalRenderData.property.id === property.id ? true : false;
    const getNotificationButton = (property) => {
      return originalValue ? 
        (
          <ValueNotificationButton 
            className="value-notification-button" 
            key="value-notification"
            onClick={() => {
              if(showPropertySuggestion){
                setModalRenderData({})
              } else {
                setModalRenderData({property: property})
              }
            }}
          >
            {/* <img className={`${showPropertySuggestion ? "close-suggestion" : "show-suggestion"}`} src={showValueSuggestionButton}/> */}
            <PlusIcon className={`${showPropertySuggestion ? "close-suggestion" : "show-suggestion"}`}/>
          </ValueNotificationButton>
        ) : 
        "";
    }
    const getBarcodeIcon = (onChange) => {
      return (<ShowBarcodeScannerButton>
        <BarcodeScanner
          onScan={(result) => {
            if(result.isFound){
              onChange(property.id, result.code);
            } else {
              toast.info("EAN could not be read", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              })
            }
          }}
        />
      </ShowBarcodeScannerButton>)
    }
    const suggestionBox = (showPropertySuggestion ? <ModalDialogBody>
      {getModalDialogContent()}
    </ModalDialogBody> : "");
    var valueNotificationButton = getNotificationButton(property);
    var propertyValue = property.value;
    // if(!propertyValue && property.default){
    //   onChangeValue(property.id, property.default);
    // }
    switch(property.type){
      case ATTRIBUTE_TYPE_TEXT:
        return (
          <FieldWrapper key={`text-attribute-${property.id}`}>
            {propertyStatusIcon}
            {valueNotificationButton}
            <CommonInputField 
              isAutoFocus={isAutoFocus}
              innerRef={propertyRef}
              value={ propertyValue != undefined ? propertyValue : currentProduct[property.id] } 
              onChange={(response) => {onChangeValue(property.id, response.target.value)}} 
              label={property.label + (property.isMandatory ? "*" : "")}
              isEditable={property.isEditable}
              onSubmit={(e) => {
                focusOnNextField(propertyRef);
              }}
            />
            {suggestionBox}
            { property.id == 'product_id' && getBarcodeIcon(onChangeValue)}
          </FieldWrapper>
        )
      
      case ATTRIBUTE_TYPE_DROP_DOWN:
        return (
          <FieldWrapper key={`drop-down-attribute-${property.id}`}>
            {propertyStatusIcon}
            {valueNotificationButton}
            <CommonDropDownField
              innerRef={propertyRef}
              id={property.id}
              label={property.label + (property.isMandatory ? "*" : "")}
              onSelect={(value) => {
                onChangeValue(property.id, value);
                if(property.isMultiselect && (value.length === 0 || (propertyValue && value.length < propertyValue.length))){
                  return;
                }
                focusOnNextField(propertyRef);
              }}
              options={props.dropDownValues[property.id]}
              isMultiSelect={property.isMultiselect}
              canAddNew={property.canAdd}
              value={ propertyValue ? propertyValue : currentProduct[property.id] }
              disabled={!property.isEditable}
              onAdd={(value) => {
                props.onCreateNewValue(property.id, value)
              }}
            />
            {suggestionBox}
          </FieldWrapper>
        )

      case ATTRIBUTE_TYPE_TEXT_AREA:
        return (
          <FieldWrapper key={`text-area-attribute-${property.id}`}>
            {propertyStatusIcon}
            {valueNotificationButton}
            <CommonTextArea 
              innerRef={propertyRef}
              value={ propertyValue != undefined ? propertyValue : currentProduct[property.id] } 
              onChange={(response) => {onChangeValue(property.id, response.target.value)}} 
              label={property.label + (property.isMandatory ? "*" : "")}
              isEditable={property.isEditable}
            />
            {suggestionBox}
          </FieldWrapper>
        )
      case ATTRIBUTE_TYPE_FLOAT:
      case ATTRIBUTE_TYPE_INTEGER:
      case ATTRIBUTE_TYPE_CURRENCY:
      case ATTRIBUTE_TYPE_MEASUREMENT:
        return (<FieldWrapper key={`number-attribute-${property.id}`}>
          {propertyStatusIcon}
          {valueNotificationButton}
          <CommonInputField 
            innerRef={propertyRef}
            value={ propertyValue != undefined ? propertyValue : currentProduct[property.id] } 
            onChange={(response) => {onChangeValue(property.id, response.target.value)}} 
            label={property.label + (property.isMandatory ? "*" : "")}
            suffix={property.type_suffix}
            variant='number'
            disabled={!property.isEditable}
            onSubmit={(e) => {
              focusOnNextField(propertyRef);
            }}
          />
          {suggestionBox}
        </FieldWrapper>)

      default:
        return (<div></div>);
    }
  }

  var isAllPropertiesFilled = true;
  var propertyFields = [];
  for(var i = 0 ; i < stateProperties.length ; i++ ){
    const property = stateProperties[i];
    var isPropertyFilled = false;
    switch(property.type){
      case ATTRIBUTE_TYPE_FLOAT:
      case ATTRIBUTE_TYPE_INTEGER:
        isPropertyFilled = (property.value == undefined && (currentProduct[property.id] != undefined && currentProduct[property.id] > 0)) ||
          (property.value > 0)
        break;
      case ATTRIBUTE_TYPE_TEXT:
      case ATTRIBUTE_TYPE_TEXT_AREA:
      case ATTRIBUTE_TYPE_DROP_DOWN:
        isPropertyFilled = (property.value == undefined && (currentProduct[property.id] != undefined && currentProduct[property.id].length > 0)) ||
          (property.value && property.value.length > 0)
        break;
      default:
        isPropertyFilled = false;
        break;
    }
    if(property.isMandatory == true && !isPropertyFilled){
      isAllPropertiesFilled = false;
    }
    propertyFields.push(getPropertyDisplayField(property, isPropertyFilled, propertyRefs[i]));
  }
  // var isAnyFieldFocussed = false;
  // for (var iFocusIndex = 0 ; iFocusIndex < propertyRefs.length ; iFocusIndex++){
  //   if(document.activeElement == propertyRefs[iFocusIndex]){
  //     isAnyFieldFocussed = true;
  //     break;
  //   }
  // }
  // var isFieldFocusNavigatorEnabled = currentFocusIndex >= 0 && currentFocusIndex < propertyRefs.length;
  // var isFieldFocusNavigatorEnabled = isAnyFieldFocussed;
  return (
    <WizardProductAttributesWrapper>
      <div className="product-detail"><b>Product SKU</b>: {currentProduct.sku}</div>
      <div className="product-attributes-section">
        <div className="product-attributes-images-wrapper">
          {currentProduct.image_front && <img className="product-image" src={currentProduct.image_front}/>}
          {currentProduct.image_back && <img className="product-image" src={currentProduct.image_back}/>}
          {currentProduct.image_label && <img className="product-image" src={currentProduct.image_label}/>}
        </div>
        <div className="product-attibutes-filler-wrapper">
          {propertyFields}
        </div>
      </div>
      <BottomRightOperationTools
        additionalRightButtons={[
          {onClick:()=>{focusOnPreviousField()}, text:"Up", icon:(<UpArrow/>)},
          {onClick:()=>{focusOnNextField()}, text:"Down", icon:(<DownArrow/>)},
          {onClick:()=>{setCurrentFocusIndex(-1)}, text:"Close", icon:(<CrossIcon height='15px'/>)}
        ]}
        showNextButton={isAllPropertiesFilled}
        onClickNext={() => {
          if(!currentProduct.attribute_stage_completed){
            const trackingRequest = {
              "order_id": props.currentOrder.id,
              "item_id": props.currentProduct.id,
              "operation": "ATTRIBUTE-FILLING",
              "user": AuthService.getUserInfo().user.id,
              "time_consumed": (Date.now() - startTime)
            };
            postRequest(`${CREATE_WAREHOUSE_TRACKING}`, trackingRequest, (response) => {}, (response) => {});
          }
          var propertyValues = {};
          stateProperties.forEach((property) => {
            var value = property.value;
            if(value != undefined){
              propertyValues[property.id] = property.value == "" ? null : property.value;
            }
          });
          props.onCompleteStep(propertyValues);
        }}
        showButtonText={true}
      />
    </WizardProductAttributesWrapper>
  )
}

export default WizardProductAttributesStep;