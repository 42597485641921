import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { cloneWarehouseItem, createBoxProducts, createWarehouseCommonData, fetchDropDownValues, fetchOrder, fetchProperties, fetchOrderItemStatusConstants, setOrderStatus, updateWarehouseCommonData, fetchOrderStatusConstants, bulkRejectItems } from './SellOrderProductsListingScreenActions';
import { useNavigate, useParams } from 'react-router';
import { ReactComponent as EditIcon } from "../../static/tildi-edit-icon.svg";
import LoadingOverlay from '../../common/components/LoadingOverlay';
import { ReactComponent as DownExpandArrow } from "../../static/tildi-down-double-arrow-icon.svg";
import { ReactComponent as BackArrow } from "../../static/tildi-double-left-arrow-icon.svg";
import CommonTreeDropDownField from '../../common/components/CommonTreeDropDownField';
import CommonDropDownField from '../../common/components/CommonDropDownField';
import BottomRightOperationTools from '../../common/components/BottomRightOperationTools';
import { ATTRIBUTE_TYPE_DROP_DOWN, ATTRIBUTE_TYPE_MEASUREMENT } from '../../common/utils/commonConstants';
import CommonTextField from '../../common/components/CommonTextField';
import ModalDialogOverlay from '../../common/components/ModalDialogOverlay';
import { PREDEFINED_REJECTION_MESSAGES, REJECTION_MESSAGE_BY_MISTAKE, REJECTION_MESSAGE_NOT_SENT, WAREHOUSE_ORDER_ITEM_STATUS_COMPLETED, WAREHOUSE_ORDER_ITEM_STATUS_IN_PROGRESS, WAREHOUSE_ORDER_ITEM_STATUS_PENDING, WAREHOUSE_ORDER_ITEM_STATUS_REJECTED } from './constants';
import WarehouseOrderItemRow from './components/WarehouseOrderItemRow';
import AllNotCompleteDialog from '../../common/components/AllNotCompeteDialog';
import OrderProductsListingScreen from '../../common/components/OrderProductsListingScreen';

const WizardProductListingWrapper = styled.div`
  position:relative;
`

const SellOrderProductListingScreen = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const orderId = params.id;
  const [currentOrder, setCurrentOrder] = useState(undefined);
  const [statusConstants, setStatusConstants] = useState(undefined);
  const [orderStatusConstants, setOrderStatusConstants] = useState(undefined);
  const [isScreenLoading, setScreenLoading] = useState(false);
  const [dropDownValues, setDropDownValues] = useState({});
  const [commonProperties, setCommonProperties] = useState([]);
  const handleFailure = () => {
    setScreenLoading(false);
  }
  useEffect(() => {
    setScreenLoading(true);
    const onOrderLoaded = (order) => {
      setScreenLoading(false);
      setCurrentOrder(order);
    };
    fetchOrder(orderId, onOrderLoaded, handleFailure)
    fetchOrderItemStatusConstants((statusConstants) => { setStatusConstants(statusConstants) }, handleFailure);
    fetchDropDownValues((dropDownValues) => { setDropDownValues(dropDownValues) }, handleFailure)
    fetchProperties((response) => { setCommonProperties(response) });
    fetchOrderStatusConstants((statusConstants) => { setOrderStatusConstants(statusConstants) }, handleFailure);
  }, [])
  const onProductSelect = (oProduct) => {
    navigate(`/sell/einkauf/item/${oProduct.id}`);
  }
  /**
   * Handling of data changes in the Box number step
   */
  const handleChangeProductCount = () => {
    setScreenLoading(true);
    const handleOnCreationOfBoxProducts = (response) => {
      setScreenLoading(false);
      loadOrder();
    }
    createBoxProducts(currentOrder.id, handleOnCreationOfBoxProducts, handleFailure);
  }
  const loadOrder = () => {
    const onOrderLoaded = (order) => {
      setCurrentOrder(order);
      setScreenLoading(false);
    }
    fetchOrder(orderId, onOrderLoaded)
  }
  const completeOrderStatus = (sOrderId, iOrderStatus) => {
    setOrderStatus(
      sOrderId,
      iOrderStatus,
      () => { navigate(`/sell`) }
    );
  }
  var commonData = currentOrder && currentOrder.common_data;
  return (
    <WizardProductListingWrapper>
      <LoadingOverlay isLoading={isScreenLoading}/>
      <OrderProductsListingScreen
        onClickBack={() => {navigate(`/sell`);}}
        orderItemStatusConstants={statusConstants}
        dropDownValues={dropDownValues}
        commonProperties={commonProperties}
        orderStatusConstants={orderStatusConstants}
        currentOrder={currentOrder}
        orderItems={currentOrder ? currentOrder.order_items : []}
        handleCreateProduct={handleChangeProductCount}
        handleOrderComplete={(sOrderId, iOrderStatus) =>{completeOrderStatus(sOrderId, iOrderStatus);}}
        onProductSelect={(oProduct)=>{onProductSelect(oProduct)}}
        canRejectItems={true}
        handleCloneItem={(itemId) => {
          // cloneWarehouseItem(itemId, (response) => {initiateFetchOrder()})
          cloneWarehouseItem(itemId, (response) => { setScreenLoading(true); loadOrder(); })
        }}
        handleCommonDataUpdate={(commonDataEditProperties, fSuccessCallBack) => {
          const postProcessing = () => {
            // setCommonDataEditProperties({});
            // closeCommonDataPropertiesWindow();
            fetchOrder(orderId, (order) => { setCurrentOrder(order); })
            fSuccessCallBack();
          }
          if (commonData) {
            updateWarehouseCommonData(commonDataEditProperties, currentOrder.id, postProcessing)
          } else {
            createWarehouseCommonData(commonDataEditProperties, currentOrder.id, postProcessing)
          }
        }}
        handleRejectItems={(itemsToReject, selectedRejectionMessage, fOnSuccess)=>{
          setScreenLoading(true);
          bulkRejectItems(itemsToReject, selectedRejectionMessage, ()=>{setScreenLoading(false);loadOrder();fOnSuccess()}, ()=>{setScreenLoading(false)})
        }}
      />
    </WizardProductListingWrapper>
  );
}

export default SellOrderProductListingScreen;
