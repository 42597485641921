import { connect, useDispatch } from "react-redux";
import styled from 'styled-components';
import SellOrdersScreenReducer, { initialState } from "./SellOrdersListingScreenReducer";
import store from "../../store";
import { useEffect, useState } from "react";
import { fetchOrders, fetchStatusConstants, ordersFetched } from "./SellOrdersListingScreenActions";
import { useNavigate } from "react-router";
import LoadingOverlay from "../../common/components/LoadingOverlay";
import { ReactComponent as BackArrow} from "../../static/tildi-double-left-arrow-icon.svg";

const SellOrdersScreenWrapper = styled.div`
    margin: 20px 15px;
    text-align: center;
    position: relative;
    .home-screen-title{
        // text-align: center;
        font-weight: bold;
        font-size: 20px;
    }
    .home-screen-text{
      margin-top: 10px;
    }
    .home-screen-filters-container{
      min-height: 50px;
      font-size: 14px;
      color: grey;
      text-align: initial;
      margin-top: 15px;
      margin-bottom: 5px;
      padding: 10px 13px;
      background: #fff1fc;
      position: relative;
      border: 1px solid black;
      .filter-send-button{
        height: 25px;
        padding: 0 5px;
        background-color: grey;
        font-size: 15px;
        min-width: 0px;
        position: absolute;
        top: 6px;
        right: 10px;
      }
      .filter-reset-button{
        position: absolute;
        bottom: 6px;
        right: 10px;
        text-decoration: underline;
        font-size: 16px;
      }

      .home-scrren-filters-wrapper{
        margin-top: 5px;
        .filter-field-wrapper{
          display: flex;
          flex-direction: row;
          align-items: center;
          .filter-input{
          }
        }
      }
    }
    .add-new-resale-order-button{
      margin: 20px;
      color: blue;
      font-weight: bold;
      font-size: 20px;
      padding: 10px;
      border: 1px solid blue;
    }
`

const Table = styled.div`
    font-size: 13px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
`

const TableRow = styled.div`
  padding: 5px 10px 5px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid black;
  margin-bottom: 5px;
  // &:nth-child(odd){
    background-color: #EDF9ED;
  // }
  // &:nth-child(even){
    // background-color: #fcfdfd;
  // } 
  .table-cell-status{
    .status_text{
      padding: 4px 5px;
      color: white;
      text-align: center;
      max-width: 70px;
      width: 70px;
      &.status_100{
        background-color: #C5C5C5;
      }
      &.status_200{
        background-color: #04bb2d;
      }
      &.status_300{
        background-color: #e1c534;
      }
      &.status_400{
        background-color: #b70000;
      }
      &.status_110{
        background-color: #e1c534;
      }
      &.status_205{
        background-color:rgb(0, 119, 255);
      }
    }
  }
`

const TableCellDataWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const TableCellData = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px;
  text-align: left;
  min-width: 110px;
  max-width: 110px;

  .table-cell-data-title{
    color: #A8B1A9;
    font-size: 10px;
  }
  .table-cell-data{
    font-size: 13px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`
const BackButton = styled.div`
  display: flex;
  font-size: 12px;
  align-items: center;
  position: absolute;
  top: 0;
  padding: 3px 10px 3px 5px;
  background: #405c43;
  color: white;
  border-radius: 15px;
  font-weight: bold;
  .back-icon{
    height: 18px;
  }
`

const HOME_SCREEN_STORE_KEY = "HomeScreen";

const SellOrdersListingScreen = (state) => {
  store.injectReducer(HOME_SCREEN_STORE_KEY, SellOrdersScreenReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isScreenLoading, setScreenLoading] = useState(false);
  const [orderNumberFilter, setOrderNumberFilter] = useState("");

  const onOrdersLoad = (orders) => {
    const onStatusConstantsLoaded = (statusConstants) => {
      setScreenLoading(false);
      dispatch(ordersFetched(orders, statusConstants));
    }
    fetchStatusConstants(onStatusConstantsLoaded);
  };

  const handleFailure = () => {
    setScreenLoading(false);
  }

  useEffect(() => {
    setScreenLoading(true);
    fetchOrders(orderNumberFilter, onOrdersLoad, handleFailure)
  }, [])

  const onClickOfOrder = (orderId) => {
    navigate(`/sell/einkauf/${orderId}`);
  }

  var tableRows = state.orders.map((order) => {
    const data = [];
    data.push(
      <TableCellData key={`cell-data-order-number_${order.id}`}>
        <div key={`cell-data-title-order-number_${order.id}`} className="table-cell-data-title">Request Number</div>
        <div key={`cell-data-order-number_${order.id}`} className="table-cell-data cell-data-order-number">
          #{order.request ? order.request['transaction_number'] : ''}
        </div>
      </TableCellData>
    )
    data.push(
      <TableCellData key={`cell-data-customer-name_${order.id}`}>
        <div key={`cell-data-title-customer-name_${order.id}`} className="table-cell-data-title">Customer Name</div>
        <div key={`cell-data-customer-name_${order.id}`} className="table-cell-data cell-data-customer-name">
          {order.request && order.request.customer ? `${order.request.customer.user['first_name']} ${order.request.customer.user['last_name']}` : ''}
        </div>
      </TableCellData>
    )
    data.push(
      <TableCellData key={`cell-data-order-date_${order.id}`}>
        <div key={`cell-data-title-order-date_${order.id}`} className="table-cell-data-title">Request Date</div>
        <div key={`cell-data-order-date_${order.id}`} className="table-cell-data cell-data-order-date">
          {order.request ? new Date(order.request['created']).toDateString() : ''}
        </div>
      </TableCellData>
    )
    data.push(
      <TableCellData key={`cell-data-order-type_${order.id}`}>
        <div key={`cell-data-title-order-type_${order.id}`} className="table-cell-data-title">Request Type</div>
        <div key={`cell-data-order-type_${order.id}`} className="table-cell-data cell-data-order-type">
          {order.request ? order.request['is_box'] ? 'Boxankauf' : 'Einzelankauf' : ''}
        </div>
      </TableCellData>
    )
    return (
      <TableRow key={order.id} className="table-row" onClick={()=>{onClickOfOrder(order.id)}}>
        <TableCellDataWrapper key={`${order.id}-table-cell-wrapper`} className="table-cell-data-wrapper">{data}</TableCellDataWrapper>
        <div key={`${order.id}-table-order-status`} className="table-cell-status">
          <div className={`status_text status_${order.status}`}>{state.statusConstants[order.status]}</div>
        </div>
      </TableRow>
    );

  });

  const filterOrders = (forcedFilter) => {
    setScreenLoading(true);
    fetchOrders(forcedFilter !== undefined ? forcedFilter : orderNumberFilter, onOrdersLoad, handleFailure)
  }

  return (
    <SellOrdersScreenWrapper>
      <LoadingOverlay isLoading={isScreenLoading}/>
      <BackButton 
        onClick={() => {navigate(`/`);}}
      >
        <BackArrow className="back-icon"/>Main Menu
      </BackButton>
      <div className="home-screen-title">Requests</div>
      <div className="home-screen-text">Please select the request you wish to work on.</div>
      <div className="home-screen-filters-container">
        <div>Filters:</div>
        <button className="filter-send-button" onClick={() => {filterOrders();}}>Filter</button>
        <div 
          className="filter-reset-button" 
          onClick={() => {
            setOrderNumberFilter("");
            filterOrders("")
          }}
        >
          Reset
        </div>
        <div className="home-scrren-filters-wrapper">
          <div className="filter-field-wrapper">
            {/* <div className="filter-label">Order Number</div> */}
            <input 
              placeholder="Request Number" 
              className="filter-input" 
              value={orderNumberFilter} 
              onChange={(event) => {setOrderNumberFilter(event.target.value)}}
              onKeyUp={(event) => {
                if(event.key === 'Enter'){
                  filterOrders();
                }
              }}
            />
          </div> 
        </div>
      </div>
      <Table className="table">
        {tableRows}
      </Table>
      {/* {params.orderType == 'resale' ? 
        <div className="add-new-resale-order-button">+ Add New Order</div> 
        : ''} */}
    </SellOrdersScreenWrapper>
  );
};

const mapStateToProps = (state) => {
  const componentState = state[HOME_SCREEN_STORE_KEY];
  return componentState ? componentState : initialState;
}

export default connect(mapStateToProps)(SellOrdersListingScreen);
