import styled from 'styled-components';
import { ReactComponent as ScanBarcodeIcon} from "../../static/tildi-scan-barcode.svg";
import Quagga from '@ericblade/quagga2';
import React from 'react';
import PropTypes from 'prop-types';

function BarcodeScanner(props){
  return (
    <label htmlFor="image-upload" className="custom-file-upload">
      <ScanBarcodeIcon/>
      <input 
        className="image-upload-input"
        id="image-upload"
        type="file" 
        accept="image/*"
        capture="environment"
        onChange={
          (e) => {
            if(e.target.files[0]){
              var reader = new FileReader();
                reader.addEventListener("load",
                  () => {
                    var base64String = reader.result;
                    var config = {
                      decoder: {
                        readers: [
                          'code_128_reader',
                          'ean_reader',
                          'ean_5_reader',
                          'ean_2_reader',
                          'ean_8_reader',
                          'code_39_reader',
                          'code_39_vin_reader',
                          'codabar_reader',
                          'upc_reader',
                          'upc_e_reader',
                          'i2of5_reader',
                          '2of5_reader',
                          'code_93_reader',
                          'code_32_reader',
                        ]
                      },
                      src: base64String,
                      numOfWorkers: 0,
                      locate: false,
                    };
                    Quagga.decodeSingle(
                      config, 
                      (result) => {
                        var response = {
                          code: result.codeResult ? result.codeResult.code : undefined,
                          isFound: result.codeResult ? true : false
                        };
                        props.onScan(response);
                      }
                    );
                  }  
                );
                if(e.target.files[0] != null){
                  reader.readAsDataURL(e.target.files[0]);
                }
            };
          }
        }
      />
    </label>
  );

}

BarcodeScanner.propTypes = {
  onScan: PropTypes.func.isRequired,
};

export default BarcodeScanner;