import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
// import placeholderImage from '../../../static/icons8-placeholder-96.png';
import 'react-html5-camera-photo/build/css/index.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import LoadingOverlay from '../../../common/components/LoadingOverlay';
import AuthService from '../../../common/components/services/AuthService';
import { CREATE_WAREHOUSE_TRACKING } from '../../../common/utils/urlPath';
import { postRequest } from '../../../common/utils/request';
import BottomRightOperationTools from '../../../common/components/BottomRightOperationTools';
import { ReactComponent as RemoveIcon} from "../../../static/tildi-trash-white-icon.svg";
import { ReactComponent as CameraIcon} from "../../../static/tildi-camera-icon.svg";
import { ReactComponent as RedoIcon} from "../../../static/tildi-recycle-icon.svg";
import { ReactComponent as PlaceholderImage} from "../../../static/tildi-image-placeholder-icon.svg";
import { ReactComponent as CrossIcon} from "../../../static/tildi-cross-icon.svg";
import { toast } from 'react-toastify';
import ConfigurationsService, { ENABLE_BACKGROUND_REMOVAL } from '../../../common/components/services/ConfigurationsService';


const WizardProductImageShootWrapper = styled.div`
  // margin-top: 10px;
  .placeholder-icon{
    padding: 20px 0;
    background-color: #ececec;
    border: 0.5px solid #6a6a6a;
    height: 80px;
  }
  div.product-shoot-details{
    position: relative;
    display: flex;
    margin-top: 20px;
    flex-direction: column;
    align-items: center;

    .image-photoshoot-info{
      text-align: center;
    }
    div.product-shoot-detail-existing-images-wrapper{
      margin-top: 20px;
      // width: 300px;
      .user-images-header{
        font-weight: bold;
        text-align: center;
      }
      .carousel-root{
        background-color: gray;
        // padding: 10px 0;
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;

        li.slide{
          display: flex;
          flex-direction: column;
          flex-flow: column;
          justify-content: center;
        }

        button.control-arrow{
          min-width: 30px;
        }
      }
    }
  
    div.photoshoot-type{
      margin-top: 20px;
      text-align: center;
      font-weight: bold;
    }
  
    div.photoshoot-camera-window-wrapper{
      margin-top: 20px;
      height:600px;
      width: 400px
      background: gray;
    }
    div.photoshoot-details {
      // margin-top: 100px;
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      
      .photo-delete-icon-wrapper{
        position: absolute;
        top: -10px;
        right: -10px;
        border-radius: 15px;
        height: 15px;
        width: 15px;
        background-color: #c11010;
        padding: 5px;
        display: flex;
        justify-content: center;
        .delete-icon{
          height: 15px;
        }
      }
      img.photoshoot-image-holder{
        max-width: 200px;
        border: 1px solid #e5e5e5;
        background-color: #e5e5e58a;
        min-width: 100px;
      }
  
      button.photoshoot-button{
        margin-top: 30px;
  
        &.take-photo-button {
          // background: #00ad17;
        }
      }
      .custom-file-upload{
        display: flex;
        margin-top: 30px;
        border: 1px solid darkgray;
        font-size: 20px;
        padding: 10px;
        background: #a3729d;
        color: white;
        cursor: pointer;
        border-radius: 40px;
        height: 40px;
        width: 40px;
        align-items: center;
        justify-content: center;
        input[type="file"]{
          display: none;
        }
      }
    }
    button.photoshoot-button{
      margin-top: 30px;
      &.next-button{
        background: #00ad17;
      }
    }
  }
`

const ProductPhotoShootSummary = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .photoshoot-list-item{
    position: relative;
    width: 100%;
    border: 1px solid black;
    display: flex;
    margin-bottom: 15px;
    .photoshoot-list-item-image-cell{
      padding: 10px;
      img.photoshoot-list-item-image{
        width: 100px;
        // max-width: 100px;
        // min-width: 100px;
        // min-height: 100px;
        border: 0.5px solid #dddddd;
        min-height: 50px;
      }
    }
    .photoshoot-list-item-title-cell{
      display: flex;
      align-items: center;
      padding: 10px;
      margin-left: 30px;
    }
    .photoshoot-item-remove{
      position: absolute;
      right: -10px;
      top: -10px;
      width: 10px;
      height: 10px;
      background-color: red;
      padding: 5px;
      border-radius: 25px;
      path{
        fill: white;
      }
    }
  }
  .next-button {
    background: #00ad17;
    position: fixed;
    bottom: 10px;
    right: 10px;
  }
  .add-new-button{
    // position: fixed;
    bottom: 30px;
    left: 35%;
    font-size: 18px;
    background-color: white;
    color: blue;
    font-weight: bold;
    padding: 0;
    text-decoration: underline;
    margin: 30px 0;
  }
`

const FRONT_IMAGE = {id: "image_front", label:"Front"};
const BACK_IMAGE = {id: "image_back", label:"Back"};
const LABEL_IMAGE = {id: "image_label", label:"Label"};
const MANDATORY_PHOTO_ITEMS = [FRONT_IMAGE/*, BACK_IMAGE, LABEL_IMAGE*/]

const WizardProductImageShootStep = (props) => {
  var isBackgroundRemovalEnabled = ConfigurationsService.getConfiguration(ENABLE_BACKGROUND_REMOVAL);
  const currentProduct = props.currentProduct;
  const requestItem = currentProduct.request_item;
  const [images, setImages] = useState(
    {
      image_front: currentProduct.image_front, 
      image_back: currentProduct.image_back, 
      image_label: currentProduct.image_label, 
      misc_images: currentProduct.misc_images ? currentProduct.misc_images : [] }
  );
  const [currentMandatoryPhotoIndex, setCurrentMandatoryPhotoIndex] = useState(0);
  const [unsavedCameraPhoto,  setUnsavedCameraPhoto] = useState("");
  const [shouldRemoveBackground, setShouldRemoveBackground] = useState(false);
  const [isImageLoading, setImageLoading] = useState(false);
  const [selectedPhotoStep, setSelectedPhotoStep] = useState(false);
  const [startTime, setStartTime] = useState(Date.now());
  var uploadImageRef = useRef(null);
  var hasAllMandatoryPhotos = true;
  for(var i = 0 ; i < MANDATORY_PHOTO_ITEMS.length ; i++){
    var mandatoryPhotoItem = MANDATORY_PHOTO_ITEMS[i];
    if(!currentProduct[mandatoryPhotoItem.id]){
      hasAllMandatoryPhotos = false;
      break;
    }
  }
  // currentProduct.image_front && currentProduct.image_back && currentProduct.image_label;
  useEffect(()=>{
    setShouldRemoveBackground(isBackgroundRemovalEnabled);
  },[]);
  useEffect(()=>{
    setImages({
      image_front: props.currentProduct.image_front, 
      image_back: props.currentProduct.image_back, 
      image_label: props.currentProduct.image_label, 
      misc_images: props.currentProduct.misc_images ? currentProduct.misc_images : [] 
    })
    if(!hasAllMandatoryPhotos){
      for(var i = 1 ; i < MANDATORY_PHOTO_ITEMS.length ; i++){
        if(!currentProduct[MANDATORY_PHOTO_ITEMS[i].id]){
          setCurrentMandatoryPhotoIndex(i-1);
          break;
        }
      }
    } else {
      setCurrentMandatoryPhotoIndex(-1);
    }
  },[props]);

  useEffect(() => {
    setImageLoading(false);
  }, [props.currentProduct.image_front, 
    props.currentProduct.image_back, 
    props.currentProduct.image_label, 
    props.currentProduct.misc_images]);
  
  const handleRequestFailure = () => {
    setImageLoading(false);
  }

  const handleSuccessfulSaveOfImage = (shouldRemoveBackground) => {
    setUnsavedCameraPhoto("");
    setSelectedPhotoStep(false);
    setImageLoading(false);
    if(shouldRemoveBackground){
      toast.info("Backgrounds will be removed in a few moments", {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      })
    }
  }

  const onClickExistingImage = (iImageIndex, oObject) => {
    if(currentMandatoryPhotoIndex > -1){
      var photoKey = MANDATORY_PHOTO_ITEMS[currentMandatoryPhotoIndex].id;
    } else if(selectedPhotoStep) {
      var photoKey = `${selectedPhotoStep.isMisc ? 'misc_images.' : ''}${selectedPhotoStep.id}`;
    }
    setImageLoading(true);
    props.onSelectionOfImage(
      {file: oObject.props.src}, 
      true, 
      photoKey, 
      shouldRemoveBackground, 
      ()=> {
        handleSuccessfulSaveOfImage(shouldRemoveBackground)
      },
      handleRequestFailure
    );
  }

  const onSaveNewImage = (fileBinary, photoId, isMisc) => {
    var base64result = typeof fileBinary === "object" ? "" : fileBinary.substr(fileBinary.indexOf(',') + 1);
    var fileTypeMatch = typeof fileBinary === "object" ? "" : fileBinary.match('data:image\/(.*?);base64,');
    var photoKey = isMisc ? 'misc_images.' + photoId : photoId;
    if(!isImageLoading){
      setImageLoading(true);
    }
    props.onSelectionOfImage(
      {file: base64result, fileType: fileTypeMatch[1]}, 
      false, 
      photoKey, 
      shouldRemoveBackground, 
      ()=> {
        handleSuccessfulSaveOfImage(shouldRemoveBackground)
      }, 
      handleRequestFailure
    );
  }

  const onClickNext = () => {
    if(currentMandatoryPhotoIndex === -1){
      if(!props.currentProduct.photoshoot_stage_completed){
        const trackingRequest = {
          "order_id": props.currentOrder.id,
          "item_id": props.currentProduct.id,
          "operation": "PHOTOSHOOT",
          "user": AuthService.getUserInfo().user.id,
          "time_consumed": (Date.now() - startTime)
        };
        postRequest(`${CREATE_WAREHOUSE_TRACKING}`, trackingRequest, (response) => {}, (response) => {});
      }
      props.onCompletionOfImagePhotoShoot();
    } else {
      setCurrentMandatoryPhotoIndex(currentMandatoryPhotoIndex + 1)
    }
  }

  var currentShootElements = [];

  const handleTakePhoto = (dataURI, shootStepId, isMisc) => {
    setImageLoading(true);
    var reader = new FileReader();
    reader.addEventListener("load",
      () => {
        onSaveNewImage(reader.result, shootStepId, isMisc)
      }  
    );
    if(dataURI){
      reader.readAsDataURL(dataURI)
    } else {
      setImageLoading(false);
    }
  }

  var addCurrentImageStep = (photoShootElements, imageLabel, photoId, imageSrc, isMisc) => {
    var unsavedImage = unsavedCameraPhoto && unsavedCameraPhoto != {} ? unsavedCameraPhoto : imageSrc;
    photoShootElements.push(
      <div key="image-photoshoot-info" className="image-photoshoot-info">Select an existing user clicked image or click or upload a new images</div>
    )
    if (requestItem){
      photoShootElements.push(
        <div key="existing-images-wrapper" className="product-shoot-detail-existing-images-wrapper">
          <div className="user-images-header">User Images</div>
          <Carousel 
            showThumbs={false}
            width="200px"
            onClickItem={onClickExistingImage}>
            {requestItem.images_urls.map((imageUrl,index) => {return (<img key={`existing-images-${index}`} src={imageUrl} />)})}
          </Carousel>
          </div>
      );
    }
    photoShootElements.push(
      <div 
        key="photoshoot-type-key" 
        className="photoshoot-type">Photoshoot: {imageLabel}
      </div>
    );
    photoShootElements.push(
      <div key="photoshoot-remove-bg-option">
        Remove Background? 
        <input 
          checked={shouldRemoveBackground} 
          type="checkbox" 
          disabled={!isBackgroundRemovalEnabled}
          onChange={(e) => {setShouldRemoveBackground(!shouldRemoveBackground)}}/>
        {!isBackgroundRemovalEnabled && <div style={{fontSize: '10px', marginTop: '5px', color: 'gray'}}>Admin has disabled Background Removal</div> }
      </div>
    );
    
    photoShootElements.push(
      <div key="photoshoot-details-key" className="photoshoot-details">
        {(!MANDATORY_PHOTO_ITEMS.find((photoItem) => photoId === photoItem.id)) && ((unsavedCameraPhoto && unsavedCameraPhoto != {}) || (imageSrc)) ?
          <div className="photo-delete-icon-wrapper" onClick={()=>{setUnsavedCameraPhoto({})}}>
            <RemoveIcon className="delete-icon"/>
          </div>
          :
          ""
        }
        {
          unsavedImage ? 
          <img alt='' className="photoshoot-image-holder" src={unsavedImage}/> 
          : 
          <PlaceholderImage onClick={()=> {uploadImageRef.current?.click()}} className="placeholder-icon"/>
        }
        <label htmlFor="image-upload" className="custom-file-upload">
          {unsavedImage ? <RedoIcon/> : <CameraIcon/>}
          <input 
            ref={uploadImageRef}
            className="image-upload-input"
            id="image-upload"
            type="file" 
            accept="image/*" 
            onChange={
              (e) => {
                if(e.target.files[0]){
                  handleTakePhoto(e.target.files[0], photoId, isMisc)
                };
              }
            }
          />
        </label>
      </div>
    );
  }

  if(currentMandatoryPhotoIndex === -1){
    if(selectedPhotoStep){
      var existingImage = undefined;
      if(selectedPhotoStep.isMisc){
        var found_misc_image_object = images.misc_images.find((misc_image_object) => {
          return misc_image_object.id === selectedPhotoStep.id
        });
        existingImage = found_misc_image_object.src
      } else {
        existingImage = images[selectedPhotoStep.id]
      }
      addCurrentImageStep(currentShootElements, selectedPhotoStep.label, selectedPhotoStep.id, existingImage, selectedPhotoStep.isMisc);
      currentShootElements.push(
        <BottomRightOperationTools key="photoshoot-conditional-back-button-tool"
          showNextButton={unsavedCameraPhoto ? false : true}
          onClickNext={() => {setSelectedPhotoStep(false)}}
          nextButtonText={"Cancel"}
          showCompleteButton={unsavedCameraPhoto ? true : false}
          onClickComplete={() => {onSaveNewImage(unsavedCameraPhoto, selectedPhotoStep.id, selectedPhotoStep.isMisc);setSelectedPhotoStep(false);}}
          completeButtonText={"Save & Proceed"}
          showButtonText={true}
        />
      );
      
    } else {
      const addNewMiscImage = () => {
        const count = images.misc_images.length + 1;
        var newMiscImage = {
          id: count,
          label: "New Misc Image " + count,
          src: ""
        };
        var copyMiscImages = [...images.misc_images];
        copyMiscImages.push(newMiscImage);
        props.onSaveMiscImage(copyMiscImages);
      }
      const onMiscImageLabelChange = (newValue, miscId) => {
        var newImageState = {...images};
        var currentMiscImage = newImageState.misc_images.find((miscImage) => {
          return miscImage.id === miscId;
        });
        currentMiscImage.label = newValue;
        setImages(newImageState);
      }
      const saveMiscImages = () => {
        props.onSaveMiscImage(images.misc_images);
      }
      const selectPhotoshootStep  = (photoId, isMisc) => {
        var selectedStepDetail = undefined;
        if(isMisc){
          var currentMiscImage = images.misc_images.find((miscImage) => {
            return miscImage.id === photoId.id;
          });
          currentMiscImage.isMisc = true;
          selectedStepDetail = currentMiscImage;
        } else {
          selectedStepDetail = photoId;
        }
        setSelectedPhotoStep(selectedStepDetail);
      }
      const removeSelectedMiscImage = (photoId) => {
        var copyMiscImages = [...images.misc_images];
        // console.log(photoId);
        var indexToRemove = -1;
        for(var i = 0 ; i < copyMiscImages.length ; i++){
          if(copyMiscImages[i].id === photoId){
            indexToRemove = i;
            break;
          }
        }
        copyMiscImages.splice(indexToRemove, 1);
        props.onSaveMiscImage(copyMiscImages);
      }

      const getPhotoshootSummaryItem = (imageTypeObject, isMisc, imageUrl) => {
        return (
          <div key={`photoshoot-item-${imageTypeObject.id}`} className="photoshoot-list-item" onClick={() => {selectPhotoshootStep(imageTypeObject, isMisc)}}>
            <div className="photoshoot-list-item-image-cell">
              {
                imageUrl ?  
                <img alt='' className="photoshoot-list-item-image" src={`${imageUrl}??test=${Date.now().toString()}`}/>
                :
                <PlaceholderImage className="placeholder-icon"/>
              }
            </div>
            <div className="photoshoot-list-item-title-cell">
              {
                isMisc ? 
                <input 
                  className="photoshoot-list-item-title-input"
                  onClick={(e) => {e.stopPropagation()}} 
                  onChange={(e) => {
                    onMiscImageLabelChange(e.target.value, imageTypeObject.id);
                  }}
                  onBlur={saveMiscImages}
                  value={imageTypeObject.label}
                /> 
                :
                <div className="photoshoot-list-item-title">{imageTypeObject.label}</div>
              }
              
            </div>
            {
              isMisc &&
              <CrossIcon
                className="photoshoot-item-remove"
                onClick={(e) => {
                  e.stopPropagation();
                  removeSelectedMiscImage(imageTypeObject.id)
                }}
              />
            }
          </div>
        )
      }

      const photosList = [];
      photosList.push(getPhotoshootSummaryItem(FRONT_IMAGE, false, images.image_front));
      photosList.push(getPhotoshootSummaryItem(BACK_IMAGE, false, images.image_back));
      photosList.push(getPhotoshootSummaryItem(LABEL_IMAGE, false, images.image_label));
      photosList.push(images.misc_images.map((miscImage) => {
        return getPhotoshootSummaryItem(miscImage, true, miscImage.src)
      }));
      currentShootElements.push(
        <ProductPhotoShootSummary key="product-photoshoot-summary-table" className="product-photoshoot-summary">
          {photosList}
          {/* <button className="photoshoot-button next-button" onClick={onClickNext}>Next</button> */}
          <button className="photoshoot-button add-new-button" onClick={addNewMiscImage}>+ Add New Image</button>
          <BottomRightOperationTools
            showNextButton={true}
            onClickNext={onClickNext}
            showButtonText={true}
          />
        </ProductPhotoShootSummary>
      );
    }
  } else {
    var currentImageSelection = MANDATORY_PHOTO_ITEMS[currentMandatoryPhotoIndex];
    var existingImage = images[currentImageSelection.id];
    var src = existingImage ? existingImage : undefined;
    addCurrentImageStep(currentShootElements, currentImageSelection.label, currentImageSelection.id, src, false);
    currentShootElements.push(
      <BottomRightOperationTools
        key="save-image-operation-tools"
        showCompleteButton={unsavedCameraPhoto ? true : false}
        onClickComplete={() => {onSaveNewImage(unsavedCameraPhoto, currentImageSelection.id, selectedPhotoStep.isMisc)}}
        completeButtonText={"Save"}
        showButtonText={true}
      />
    )
    var saveImage = images[currentImageSelection.id];
  }
  return (
    <WizardProductImageShootWrapper className="wizard-product-photoshoot-step-wrapper">
          <div className="product-detail"><b>Product SKU</b>: {currentProduct.sku}</div>
          <div className="product-shoot-details">
            {currentShootElements}
            {
              saveImage ? 
              <button key="photoshoot-conditional-next-button" className="photoshoot-button next-button" onClick={onClickNext}>Next</button>
              : 
              ""
            }
            <LoadingOverlay isLoading={isImageLoading}/>
          </div>
    </WizardProductImageShootWrapper>
  )
}

export default WizardProductImageShootStep;