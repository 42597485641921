import styled from 'styled-components';
import ModalDialogOverlay from './ModalDialogOverlay';
import { WAREHOUSE_ORDER_ITEM_STATUS_IN_PROGRESS, WAREHOUSE_ORDER_ITEM_STATUS_PENDING } from '../../screens/SellOrderProductListingScreen/constants';
import { ReactComponent as PlaceholderImage} from "../../static/tildi-image-placeholder-icon.svg";

import PropTypes from 'prop-types';

const AllNotCompleteDialogWrapper = styled.div`
  position: absolute;
  top: 5%;
  max-height: 80%;
  margin: 20px;
  background-color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  .all-not-complete-title{
    text-align: center;
  }
  .not-completed-products-list{
    overflow: auto;
    width: 90%;
    border-radius: 0;
    .pending-item-detail{
      margin: 5px 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      align-content: center;
      justify-content: flex-start;
      .pending-item-image{
        // min-width: 50px;
        img{
          width: 50px;
        }
      }
      .pending-item-label{
        margin: 0 20px;
      } 
    }
  }
  .all-not-complete-dialog-buttons{
    button{
      margin: 0 5px;
    }
  }
`

const AllNotCompleteDialog = (props) => {
  const showAllNotCompleteDialog = props.showAllNotCompleteDialog;
  const currentOrder = props.currentOrder;
  return (<ModalDialogOverlay isShown={showAllNotCompleteDialog} onClose={() => { props.onCloseDialog() }}>
  <AllNotCompleteDialogWrapper
    onClick={(e) => { e.stopPropagation(); }}
  >
    <h3 className='all-not-complete-title'>There are some products still pending to be checked in!</h3>
    <div>
      Some products are still yet to be checked in. Woud you like to mark them as rejected?
    </div>
    <div className="not-completed-products-list">
      {currentOrder && currentOrder['order_items'].filter((orderItem) => {
        return [WAREHOUSE_ORDER_ITEM_STATUS_PENDING, WAREHOUSE_ORDER_ITEM_STATUS_IN_PROGRESS].includes(orderItem.status)
      }).map((orderItem) => {
        var label = orderItem.label;
        const isRequestItemAvailable = orderItem['request_item'] && orderItem['request_item']['status'];
        if (!label && isRequestItemAvailable) {
          label = orderItem['request_item']['article']['name'];
        }
        var imageUrl = '';
        if (isRequestItemAvailable) {
          imageUrl = orderItem['request_item']['article']['images_urls'][0];
        } else if(orderItem['image_front']){
          imageUrl = orderItem['image_front'];
        }
        return (
          <div className="pending-item-detail">
            <div className="pending-item-image">
              {imageUrl ? 
                <img alt='' src={imageUrl} />
                :
                <PlaceholderImage className="placeholder-icon"/>
              }
            </div>
            <div>
              <div className="pending-item-label">{orderItem['sku']}</div>
              <div className="pending-item-label">{label}</div>
            </div>
          </div>
        )
      })}
    </div>
    <div className="all-not-complete-dialog-buttons">
      <button
        onClick={() => {
          props.onRejectItems(currentOrder['order_items'].filter((orderItem) => {
            return [WAREHOUSE_ORDER_ITEM_STATUS_PENDING, WAREHOUSE_ORDER_ITEM_STATUS_IN_PROGRESS].includes(orderItem.status)
          }));
        }}>
        Reject All
      </button>
      <button className="cancel-button" onClick={() => { props.onCloseDialog() }}>Cancel</button>
    </div>
  </AllNotCompleteDialogWrapper>
</ModalDialogOverlay>);
}

AllNotCompleteDialog.propTypes = {
  showAllNotCompleteDialog: PropTypes.bool,
  currentOrder: PropTypes.object,
  onCloseDialog: PropTypes.func,
  onRejectItems: PropTypes.func,
}

export default AllNotCompleteDialog;
